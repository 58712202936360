.contact_modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transform: translateY(-20px);
  transition: all 0.5s ease-in-out;
  transition-delay: 0.4s;
  &.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
}

.contact_modal--bg {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000040;
}

.contact_modal_wrapper {
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto;
  position: absolute;
  /* display: flex; */
  width: 100%;
  z-index: 99999;
  padding: 40px 40px;
  height: 100%;
  min-height: 500px;
  max-height: 550px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../images/modal_bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.35);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  animation-name: slide-down;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  @media screen and (max-width: 767px) {
    max-width: 380px;
    max-height: 550px;
  }
  @media screen and (max-width: 480px) {
    max-width: 350px;
    max-height: 550px;
    padding: 30px 20px;
  }
  @media screen and (max-width: 360px) {
    max-width: 320px;
    max-height: 500px;
    padding: 30px 20px;
  }
}
@keyframes slide-down {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
button.contact_modal_close_btn {
  position: absolute;
  top: 30px;
  right: 30px;
  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 480px) {
    top: -40px;
    right: -0;
    padding: 5px;
    background-color: #fff;
  }
}
.contact-submitbtn {
  padding: 8px 15px !important;
  font-size: 0.9rem !important;
}
.contact_form_wrapper {
  gap: 1.4rem;
  justify-content: center;
}

body.no-scroll {
  overflow: hidden;
}

.form-control {
  border-color: rgba($color: #000000, $alpha: 0.6) !important;
  @media screen and (max-width: 767px) {
    padding: 0.15rem;
  }
}

.snack_bar {
  opacity: 0;
  visibility: hidden;
  font-size: 0.9rem;
  &.show {
    opacity: 1;
    visibility: visible;
  }
}
.form_input_wrapper {
  position: relative;
}

.invalid-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.7rem !important;
  color: #dc3545;
  position: absolute;
  bottom: -20px;
  left: 0;
}
