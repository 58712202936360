.navigation_bar {
  width: 100%;
  /* display: flex; */
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100px;
  z-index: 99;
  &_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    .navbar_logo {
      max-width: 200px;
      height: auto;
      @media screen and (max-width: 576px) {
        max-width: 160px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .navbar_menu {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    @media screen and (max-width: 990px) {
      display: none;
    }
    &_item {
      a {
        color: $primary;
        position: relative;
        padding-bottom: 4px;
        font-family: "Inter", sans-serif;
        font-weight: 500;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          max-width: 0;
          width: 100%;
          height: 1px;
          background-color: $primary;
          transition: max-width 0.3s ease-in-out;
        }
        &:hover {
          &:after {
            max-width: 100%;
          }
        }
      }
    }
  }
  .menu_toggle {
    width: 40px;
    height: 40px;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    display: none;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 990px) {
      display: flex;
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }

  .close_button {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 40px;
    height: 40px;
    background: none;
    padding: 0;
    margin: 0;
    border: none;
    display: none;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
    transition-delay: 500ms;
    @media screen and (max-width: 576px) {
      top: 40px;
      right: 25px;
    }
    svg {
      width: 25px;
      height: 25px;
    }
  }
}
body.menu_open {
  overflow: hidden;
  .close_button {
    display: flex;
  }
  .menu_toggle {
    display: none;
  }
}
.mobile_menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 0;
  background: #c9dde6;
  z-index: 98;
  overflow: hidden;
  &_inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &_items {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
  }
  &_item {
    font-size: 1.5rem;
    overflow: hidden;
    a {
      color: $accent;
      &:hover {
        color: rgba($color: #000000, $alpha: 0.5);
      }
    }
  }
}
