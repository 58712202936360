.cubicsoft_hero_section {
  background: rgba($color: $primary, $alpha: 0.09);
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .cubicsoft_hero {
    &_content {
      max-width: 70%;
      margin: 0 auto;
      padding-top: 100px;
      @media screen and (max-width: 990px) {
        max-width: 80%;
      }
      @media screen and (max-width: 576px) {
        max-width: 100%;
      }
    }
  }
}

.banner_image {
  position: relative;
  z-index: 1;
  img {
    margin-top: -80px;
    @media screen and (max-width: 700px) {
      margin-top: -50px;
    }
  }
}

.about_section_wrapper {
  display: flex;
  align-items: center;
  gap: 4rem;
  @media screen and (max-width: 990px) {
    flex-direction: column;
  }
  .about_left {
    width: 50%;
    @media screen and (max-width: 990px) {
      width: 100%;
    }
    .paragraph {
      max-width: 90%;
    }
  }
  .about_right {
    width: 50%;
    @media screen and (max-width: 990px) {
      width: 100%;
      img {
        width: 100%;
        height: 400px;
        max-height: 400px;
        object-fit: cover;
      }
    }
  }
}

.about_icon_section {
  padding: 80px 0 0;
}
.about_icons {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.about_icon_item {
  width: calc(33.3% - 2rem);
  display: inline-flex;
  flex-direction: column;
  position: relative;
  gap: 1.4rem;
  &_top svg {
    width: 60px;
    height: 60px;
  }
  &_content {
    max-width: 80%;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 1);
  }
  &:last-child {
    &::after {
      display: none;
    }
  }
}
section#services {
  padding-bottom: 0 !important;
}
//testimonial
#testimonials {
  background-color: #bcd0dd66;
}
.testimonial_wrapper {
  max-width: 60%;
  margin: auto;
  padding: 20px 0;
  @media screen and (max-width: 1024px) {
    max-width: 75%;
  }
  @media screen and (max-width: 1024px) {
    max-width: 100%;
    padding: 50px 50px;
  }
  @media screen and (max-width: 576px) {
    padding: 30px 20px;
  }
  &_bg {
    width: 100%;
    height: 100px;
    // background: rgba($color: $primary, $alpha: 1);
    display: flex;
    position: relative;
    @media screen and (max-width: 1024px) {
      height: 80px;
    }
    @media screen and (max-width: 576px) {
      height: 40px;
    }
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      width: 120px;
      height: 120px;
      background: rgba($color: $primary, $alpha: 1);
      left: 0;
      z-index: -1;
      @media screen and (max-width: 1024px) {
        width: 80px;
        height: 80px;
      }
      @media screen and (max-width: 576px) {
        width: 40px;
        height: 40px;
      }
    }

    svg {
      width: 120px;
      height: 120px;
      fill: rgba($color: $accent, $alpha: 1);
      transform: translateX(-50%);
      position: relative;
      @media screen and (max-width: 1024px) {
        width: 80px;
        height: 80px;
      }
      @media screen and (max-width: 576px) {
        width: 40px;
        height: 40px;
      }
    }
    &:last-child {
      justify-content: flex-end;
      align-items: flex-start;
      &::after {
        left: unset;
        right: 0;
      }
      svg {
        transform: translateX(50%);
      }
    }
  }
  .testimonial_slider {
    padding: 10px 0;
    position: relative;
    &_item {
      text-align: center;
      padding: 30px 100px;
      @media screen and (max-width: 700px) {
        padding: 30px 50px;
      }
      @media screen and (max-width: 576px) {
        padding: 30px 15px;
      }
    }
    .testimonial_navigation {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1000;
      @media screen and (max-width: 700px) {
        position: relative;
        top: 0;
        transform: none;
        justify-content: flex-start;
        gap: 2rem;
      }
    }
  }
}
.next-button,
.prev-button {
  background: none;
  border: 1px solid $primary;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  svg {
    width: 20px;
    height: 20px;
    fill: rgba($color: $accent, $alpha: 1);
  }
  &:not(.swiper-button-disabled):hover {
    background: $primary;
    svg {
      fill: rgba($color: $accent, $alpha: 1);
    }
  }
  &.swiper-button-disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
}

//clients
.clients_wrapper {
  padding: 80px 0;
  max-width: 80%;
  .client_top_content {
    max-width: 80%;
  }
  .clients_list {
    // display: grid;
    // grid-template-columns: repeat(4, 1fr);
    // margin-top: 40px;
    // /* padding: 30px; */
    // gap: 5rem;
    &_item {
      border: 1px solid rgba($color: $accent, $alpha: 0.4);
      padding: 20px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width: 480px) {
        padding: 15px;
      }
      img {
        max-height: 60px;
        width: auto;
        transition: all 0.3s ease-in-out;
        @media screen and (max-width: 576px) {
          max-height: 30px;
        }
      }
    }
  }
}
.review_navigation {
  margin-top: 50px;
  display: inline-flex;
  gap: 2rem;
  align-items: center;
  justify-content: flex-start;
}

//services

.services_items {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;
  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .services_item {
    display: flex;
    align-items: center;
    background: rgba($color: $primary, $alpha: 0.2);
    padding: 40px 20px;
    gap: 1.5rem;
    min-height: 280px;
    border: 1px solid rgba($color: $accent, $alpha: 0.2);
    &_left {
      &_content {
        max-width: 90%;
        h6 {
          font-size: 1.5rem;
        }
        p {
          color: rgba($color: $accent, $alpha: 1);
          font-size: 1rem;
        }
      }
    }
    &_right_icon svg {
      width: 150px;
      height: auto;
      max-width: 100%;
    }
  }
}

.servces_items_wrapper {
  padding: 30px 0;
  max-width: 95%;
}

//contact
#contact {
  background-color: $primary;
}
.contact_wrapper {
  padding: 100px 0 50px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  .contact_left {
    width: 70%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &_inner {
      max-width: 80%;
      @media screen and (max-width: 576px) {
        max-width: 100%;
      }
    }
  }
  .contact_right {
    width: 30%;
    display: inline-flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .contact_title {
    font-size: 1.6rem;
    font-weight: 300;
    color: rgba($color: #fff, $alpha: 1);
    font-family: "Inter", sans-serif;
    @media screen and (max-width: 576px) {
      font-size: 1.3rem;
    }
  }
}
.contact_mail {
  display: inline-flex;
  gap: 0.8rem;
  align-items: center;
  p,
  a {
    color: #fff;
    font-size: 1.2rem;
    &:hover {
      color: rgba($color: #fff, $alpha: 0.7);
    }
  }
  span {
    width: 50px;
    height: 50px;
    border: 1px solid rgba($color: #fff, $alpha: 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 20px;
      height: 20px;
      fill: #fff;
    }
  }
}
.contact_bottom {
  border-top: 1px solid rgba($color: #fff, $alpha: 0.5);
  color: rgba($color: #fff, $alpha: 0.7);
}
.locations {
  display: flex;
  justify-content: flex-start;
  gap: 5rem;
  align-items: flex-start;
  border-top: 1px solid rgba($color: #fff, $alpha: 0.4);
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 2rem;
  }
  .paragraph {
    color: rgba($color: #fff, $alpha: 0.8);
  }
  &_left {
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &_right {
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.offerings_section {
  background: $primary;
  width: 100%;
  .offerings {
    max-width: 75%;
    @media screen and (max-width: 576px) {
      max-width: 100%;
    }
    h2 {
      color: #fff;
    }
    .paragraph {
      color: rgba($color: #fff, $alpha: 0.9);
    }
  }
}
